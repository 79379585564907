import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { getColor } from '@bb-ui/react-library/dist/components/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',
    gridGap: theme.spacing(2),
    backgroundColor: getColor('background.default', theme),
    color: getColor('text.primary', theme),
  },
}));
