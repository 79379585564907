import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FeatureFlagDefinitionData,
  useFeatureFlagDefinitions,
  FeatureFlagDefinitionDetailPanel,
  FeatureFlagEditDialog,
  FeatureFlagDefinitionGlobalSettingsPanel,
} from '@bb-config-ui/feature-flags';
import { useParams } from 'react-router';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { Typography } from '@bb-ui/react-library';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorMessage } from 'components/ErrorMessage';
import { useSnackbar } from 'hooks/useSnackbar';
import { useAuthContext } from 'contexts/AuthContext';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(2),
      display: 'inline-flex',
      width: '100%',
      justifyContent: 'space-between',
    },
    section: {
      marginBottom: theme.spacing(6),
    },
    icon: {
      minHeight: 'fit-content',
    },
  })
);

export const FeatureFlagDefinitionInformation: React.FC = (props) => {
  const { idToken } = useAuthContext();
  const parseIdToken = idToken ?? '';
  const {
    error,
    loading: fetchLoading,
    flagDefinitions,
    loadingRequests,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
    updateFeatureFlagDefinition,
  } = useFeatureFlagDefinitions({ accessToken: parseIdToken });
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { flagKey } = useParams<{ flagKey: string }>();
  const flag: FeatureFlagDefinitionData | undefined = flagDefinitions?.find(
    (el: FeatureFlagDefinitionData) => el?.flagKey === flagKey
  );
  const [currentFeatureFlag, setCurrentFeatureFlag] = React.useState<FeatureFlagDefinitionData>();
  const { enqueueSnackbar } = useSnackbar();
  const [isDialogOpen, toggleDialogOpen] = React.useState(false);

  let flagInfo: React.ReactElement;

  React.useEffect(() => {
    setCurrentFeatureFlag(flag);
  }, [flag]);

  let editDialog = <></>;
  if (fetchLoading || loadingRequests > 0) {
    flagInfo = <LoadingIndicator data-testid="fnds-feature-flag-information-loading" />;
  } else if (currentFeatureFlag) {
    flagInfo = (
      <div data-testid="feature-flag-information-detail-panel">
        <div className={classes.section} data-testid="flag-information-details">
          <div className={classes.heading}>
            <Typography component="h2" variant="h4">
              {t('featureFlagDefinitionInfo.subTitle')}
            </Typography>
          </div>
          <FeatureFlagDefinitionDetailPanel featureFlagData={currentFeatureFlag} />
        </div>

        <div className={classes.section} data-testid="flag-information-global-settings">
          <div className={classes.heading}>
            <Typography component="h2" variant="h4">
              {t('featureFlagDefinitionInfo.globalSettingsTitle')}
            </Typography>
            <IconButton
              className={classes.icon}
              aria-haspopup="dialog"
              data-testid="submit-flag-definition-edit-btn"
              onClick={() => toggleDialogOpen(true)}
            >
              <Edit />
            </IconButton>
          </div>
          <FeatureFlagDefinitionGlobalSettingsPanel featureFlagData={currentFeatureFlag} />
        </div>
      </div>
    );
    editDialog = (
      <FeatureFlagEditDialog
        isDialogOpen={isDialogOpen}
        dialogToggle={toggleDialogOpen}
        flagDefinition={currentFeatureFlag}
        updateFeatureFlagDefinition={updateFeatureFlagDefinition}
      />
    );
  } else {
    flagInfo = (
      <ErrorMessage
        data-testid="fnds-feature-flag-information-error"
        title={t('featureFlagDefinitionInfo.error')}
        message={error?.message}
        variant="block"
      />
    );
  }

  // DefaultValue update handling
  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      setCurrentFeatureFlag(succeededRequests.pop()?.response.data);
      enqueueSnackbar(t('featureFlagDefinitionInfo.updateSuccess'), { variant: 'info' });
      clearSucceededRequests();
    }
  }, [clearSucceededRequests, enqueueSnackbar, succeededRequests, t]);

  // DefaultValue update handling
  React.useEffect(() => {
    if (failedRequests.length > 0) {
      enqueueSnackbar(
        t('featureFlagDefinitionInfo.updateError') + failedRequests[0]?.error.message,
        { variant: 'error' }
      );
      clearFailedRequests();
    }
  }, [clearFailedRequests, enqueueSnackbar, failedRequests, t]);

  return (
    <div data-testid="flag-information-page">
      {editDialog}
      {flagInfo}
    </div>
  );
};
