// Displays the user's avatar with a menu allowing signout.

import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Avatar } from '@bb-ui/react-library/dist/components/Avatar';
import { BbThemeProvider } from '@bb-ui/react-library/dist/components/BbThemeProvider';
import { Menu } from '@bb-ui/react-library/dist/components/Menu';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { OutlineButton } from '@bb-ui/react-library';
import { IButtonProps } from '@bb-ui/react-library/dist/components/Button/Button.types';
import { useAuthContext } from 'contexts/AuthContext';

export const useStyles = makeStyles(() =>
  createStyles({
    menuItem: {
      width: '200px',
    },
  })
);

const LinkButton = OutlineButton as React.ElementType<
  IButtonProps & {
    component?: React.ElementType;
    to?: string;
  }
>;

export const HeaderUserMenu: React.FunctionComponent = (props) => {
  const classes = useStyles(props);
  const { user } = useAuthContext();
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement>();
  const { t } = useTranslation();

  // Loosely typing to allow apply Button props.
  const LooseMenuItem = MenuItem as any;

  function closeMenu() {
    setMenuAnchor(undefined);
  }

  function onClickButton(event: React.MouseEvent<HTMLButtonElement>) {
    setMenuAnchor(event.currentTarget);
  }

  if (!user) {
    return (
      <LinkButton component={Link} to="/sign-out">
        {t('auth.signOut')}
      </LinkButton>
    );
  }

  return (
    <div data-testid="user-avatar">
      <IconButton
        onClick={onClickButton}
        aria-controls={menuAnchor ? 'header-user-menu' : undefined}
        aria-expanded={!!menuAnchor}
        aria-haspopup="menu"
        aria-label={t('headerUserMenu.menuToggleLabel')}
      >
        <Avatar size="small" alt={user.nickname} src={user.picture} />
      </IconButton>
      <BbThemeProvider theme="light">
        <Menu
          anchorEl={menuAnchor}
          id="header-user-menu"
          onClose={closeMenu}
          open={!!menuAnchor}
          MenuListProps={{
            'aria-label': t('headerUserMenu.menuAriaLabel'),
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          data-testid="header-user-menu"
        >
          <LooseMenuItem
            className={classes.menuItem}
            button
            component={Link}
            to="/sign-out"
            data-testid="header-user-menu-sign-out"
          >
            {t('auth.signOut')}
          </LooseMenuItem>
        </Menu>
      </BbThemeProvider>
    </div>
  );
};
