import * as React from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';
import { useAuthContext } from 'contexts/AuthContext';
import {
  FailedRequest,
  FetchOptions,
  SucceededRequest,
  UseRestApiResult,
} from './useRestApi.types';

export function useRestApi<TResponse = any, TError = any>(
  url: string,
  fetchOptions?: FetchOptions
): UseRestApiResult<TResponse, TError> {
  const { idToken } = useAuthContext();
  const authConfig = {
    headers: { Authorization: `Bearer ${idToken}` },
  };
  const [{ data, error, loading }, fetch] = useAxios<TResponse, TError>(
    { url, ...authConfig },
    fetchOptions
  );
  const [failedRequests, setFailedRequests] = React.useState<FailedRequest[]>([]);
  const [loadingRequests, setLoadingRequests] = React.useState(0);
  const [succeededRequests, setSucceededRequests] = React.useState<SucceededRequest[]>([]);

  const doDelete = React.useCallback(
    (id: string, annotation?: any) => {
      setLoadingRequests((loading) => loading + 1);
      axios
        .delete(`${url}/${id}`, authConfig)
        .then((response) => {
          setLoadingRequests((loading) => loading - 1);
          setSucceededRequests((reqs) => [
            ...reqs,
            { annotation, id, response, requestMethod: 'DELETE' },
          ]);

          // Our retrieved data is now stale.
          if (!fetchOptions?.manual) {
            fetch();
          }
        })
        .catch((error) => {
          setLoadingRequests((loading) => loading - 1);
          setFailedRequests((reqs) => [
            ...reqs,
            { annotation, error, id, requestMethod: 'DELETE', response: null },
          ]);

          // Our retrieved data is not stale, so far as we know. A consumer can
          // force a manual update if they think otherwise.
        });
    },
    [authConfig, fetch, fetchOptions, url]
  );

  const doPatch = React.useCallback(
    (id: string, body: any, annotation?: any) => {
      setLoadingRequests((loading) => loading + 1);
      axios
        .patch(`${url}/${id}`, body, authConfig)
        .then((response) => {
          setLoadingRequests((loading) => loading - 1);
          setSucceededRequests((reqs) => [
            ...reqs,
            { annotation, id, response, requestBody: body, requestMethod: 'PATCH' },
          ]);

          // Our retrieved data is now stale.
          if (!fetchOptions?.manual) {
            fetch();
          }
        })
        .catch((error) => {
          setLoadingRequests((loading) => loading - 1);
          setFailedRequests((reqs) => [
            ...reqs,
            { annotation, error, id, requestBody: body, requestMethod: 'PATCH', response: null },
          ]);

          // Our retrieved data is not stale, so far as we know. A consumer can
          // force a manual update if they think otherwise.
        });
    },
    [authConfig, fetch, fetchOptions, url]
  );

  const doPost = React.useCallback(
    (body: any, annotation?: any) => {
      setLoadingRequests((loading) => loading + 1);
      axios
        .post(url, body, authConfig)
        .then((response) => {
          setLoadingRequests((loading) => loading - 1);
          setSucceededRequests((reqs) => [
            ...reqs,
            { annotation, response, requestBody: body, requestMethod: 'POST' },
          ]);

          // Our retrieved data is now stale.
          if (!fetchOptions?.manual) {
            fetch();
          }
        })
        .catch((error) => {
          setLoadingRequests((loading) => loading - 1);
          setFailedRequests((reqs) => [
            ...reqs,
            { annotation, error, requestBody: body, requestMethod: 'POST', response: null },
          ]);

          // Our retrieved data is not stale, so far as we know. A consumer can
          // force a manual update if they think otherwise.
        });
    },
    [authConfig, fetch, fetchOptions, url]
  );

  const clearFailedRequests = React.useCallback(() => setFailedRequests([]), []);
  const clearSucceededRequests = React.useCallback(() => setSucceededRequests([]), []);

  return {
    clearFailedRequests,
    clearSucceededRequests,
    data,
    doDelete,
    doPost,
    doPatch,
    error,
    failedRequests,
    loading,
    fetch,
    succeededRequests,
    loadingRequests: loadingRequests > 0,
  };
}

export default useRestApi;
