// See https://github.com/blackboard-foundations/bb-tenant/blob/master/openapi/Tenant.v1.yaml

export const TenantTypes = [
  'Customer',
  'Learn',
  'Student',
  'Reach',
  'Raise',
];

export interface TenantData {
  id: string;
  name: string;
  description: string;
  uriComponent?: string;
  clientId?: string;
  authProviderId?: string;
  isHidden?: boolean;
  isDeleted?: boolean;
  hostname?: string;
  parentTenant?: string;
  tenantType?: string;
  region?: string;
  displayName?: string;
  fleetId?: string;
}

/**
 * Possible types of Auth0 connections:
 * - 'LearnConnector' equates to Foundations Only in the UI.
 * - 'SAML' for SAML connections
 * - 'AzureAD' for Azure AD connections
 */
export type IdpConnectionType = 'LearnConnector' | 'SAML' | 'AzureAD';
export interface IdentityProvidersData {
  id: string;
  name: string;
  type: IdpConnectionType;
  displayName: string;
  description: string;
}
