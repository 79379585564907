/**
 * TODO: move to bb-ui
 */

import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { ArrowLeft } from '@bb-ui/icons/dist/small/ArrowLeft';
import { ArrowRight } from '@bb-ui/icons/dist/small/ArrowRight';
import { HideOffScreen } from '@bb-ui/react-library/dist/components/HideOffScreen';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  helperText: {
    color: 'inherit',
    fontSize: theme.typography.fontSizeDefault,
    margin: theme.spacing(0, 0, 0.125, 1),
  },
  label: {
    margin: theme.spacing(0, 1, 0.125, 0),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export interface PaginationProps extends Partial<HTMLDivElement> {
  /** Input id. */
  id: string;
  /** Zero-based index of the current page. */
  page: number;
  /** Total number of items/rows. */
  count: number;
  /** Number of items/rows per page. */
  itemsPerPage: number;
  /**
   * Callback fired when the page is changed.
   * page: Zero-based number of the selected page.
   */
  onChangePage: (page: number) => void;
  /** The following strings need to be provided if translations are required. */
  previousButtonLabel?: string;
  nextButtonLabel?: string;
  pageSelectLabel?: string;
  pageSelectAriaLabel?: ({ page, pages }: { page: number; pages: number }) => string;
  ofPages?: ({ pages }: { pages: number }) => string;
}

export const Pagination: React.FunctionComponent<PaginationProps> = (props) => {
  const { page, count, itemsPerPage, onChangePage, className, id, previousButtonLabel, nextButtonLabel,
    pageSelectLabel, pageSelectAriaLabel, ofPages } = props;
  const classes = useStyles(props);
  const pages = Math.ceil(count / itemsPerPage);

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChangePage(Number(event.target.value));
  }

  function previousPage() {
    onChangePage(page - 1);
  }

  function nextPage() {
    onChangePage(page + 1);
  }

  return (
    <div className={classnames(classes.container, className)}>
      <IconButton
        onClick={previousPage}
        aria-label={previousButtonLabel}
        disabled={page === 0}
      >
        <ArrowLeft />
      </IconButton>
      <HideOffScreen id="page-select-aria-label">
        {pageSelectAriaLabel!({ page: page + 1, pages })}
      </HideOffScreen>
      <TextField
        select
        className={classes.textField}
        id={id}
        label={pageSelectLabel}
        value={page}
        onChange={handleOnChange}
        SelectProps={{
          native: true,
          inputProps: {
            'aria-labelledby': 'page-select-aria-label',
          },
        }}
        InputLabelProps={{
          className: classes.label,
        }}
        disabled={pages < 2}
        helperText={ofPages!({ pages })}
        FormHelperTextProps={{
          className: classes.helperText,
          'aria-hidden': true,
        }}
      >
        {[...Array(pages)].map((e, i) => (
          <option key={i} value={i}>{String(i + 1)}</option>
        ))}
      </TextField>
      <IconButton
        onClick={nextPage}
        aria-label={nextButtonLabel}
        disabled={page === pages - 1}
      >
        <ArrowRight />
      </IconButton>
    </div>
  );
};

Pagination.defaultProps = {
  previousButtonLabel: 'Previous page',
  nextButtonLabel: 'Next page',
  pageSelectLabel: 'Page',
  pageSelectAriaLabel: ({ page, pages }) => `Page ${page} of ${pages}.`,
  ofPages: ({ pages }) => `of ${pages}`,
};

export default Pagination;
