import { useAppConfigContext } from 'contexts/AppConfigContext';
import { AppRouteContextProvider } from 'contexts/AppRouteContext';
import { Callback } from 'pages/Callback';
import { ErrorPage } from 'pages/Error';
import { ExternalPlaceholder } from 'pages/ExternalPlaceholder/ExternalPlaceholder';
import { FeatureFlagDefinition } from 'pages/FeatureFlag';
import { FeatureFlagDefinitionInformation } from 'pages/FeatureFlag/FeatureFlagDefinitionInformation';
import { FeatureFlagRegions } from 'pages/FeatureFlag/FeatureFlagRegions/FeatureFlagRegions';
import { FeatureFlagTenants } from 'pages/FeatureFlag/FeatureFlagTenants';
import { FeatureFlagList } from 'pages/FeatureFlagList';
import { GroupList } from 'pages/GroupList';
import { Home } from 'pages/Home';
import { LicenseList } from 'pages/LicenseList';
import { SignIn } from 'pages/SignIn';
import { SignOut } from 'pages/SignOut';
import { Tenant } from 'pages/Tenant';
import { TenantAuth } from 'pages/Tenant/TenantAuth';
import { TenantDisplay } from 'pages/Tenant/TenantDisplay';
import { TenantFeatureFlags } from 'pages/Tenant/TenantFeatureFlags';
import { TenantGroups } from 'pages/Tenant/TenantGroups';
import { TenantInformation } from 'pages/Tenant/TenantInformation';
import { TenantLicenses } from 'pages/Tenant/TenantLicenses';
import { TenantIdp } from 'pages/TenantIdp';
import { TenantList } from 'pages/TenantList';
import * as React from 'react';
import { Switch } from 'react-router-dom';
import { FeatureFlagFleets } from '../pages/FeatureFlag/FeatureFlagFleets';
import { AppRoute, AppRouteProps } from './AppRoute';

// See https://reactrouter.com/web/example/route-config
// The order of items corresponds to order of tabs in the UI.

export const externalRoutes: AppRouteProps[] = [
  {
    component: Callback,
    path: '/callback',
    public: true,
  },
  {
    component: ExternalPlaceholder,
    path: '/:tenantId',
  },

  // There are no /sign-in-callback or / (top-level) routes defined here.
  // Instead, <AuthProvider> handles those. See that component for more
  // explanation.
];

export const internalRoutes: AppRouteProps[] = [
  {
    component: Home,
    exact: true,
    path: '/',
  },
  {
    component: Callback,
    path: '/callback',
    public: true,
  },
  {
    component: SignIn,
    path: '/sign-in',
    public: true,
  },
  {
    component: SignOut,
    path: '/sign-out',
    public: true,
  },
  {
    component: TenantList,
    exact: true,
    hasTab: true,
    name: 'tenantList.pageTitle',
    path: '/tenants',
    authorization: [
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
  },
  {
    component: LicenseList,
    hasTab: true,
    name: 'licenseList.pageTitle',
    path: '/licenses',
  },
  {
    component: GroupList,
    hasTab: true,
    name: 'groupList.pageTitle',
    path: '/groups',
    authorization: [
      {
        service: 'fnds-group',
        permission: 'read',
      },
    ],
  },
  {
    component: FeatureFlagList,
    exact: true,
    hasTab: true,
    name: 'featureFlagList.pageTitle',
    path: '/feature-flags',
  },
  {
    component: TenantIdp,
    exact: true,
    name: 'tenantIdp.pageTitle',
    path: '/tenants/:tenantId/auth/:idpId',
    authorization: [
      {
        service: 'identity-provider',
        permission: 'read',
      },
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
  },
  {
    component: Tenant,
    path: '/tenants/:tenantId',
    authorization: [
      {
        service: 'tenant',
        permission: 'read',
      },
    ],
    routes: [
      {
        component: TenantInformation,
        exact: true,
        name: 'tenantProfile.pageTitle',
        path: '/tenants/:tenantId',
      },
      {
        component: TenantInformation,
        exact: true,
        hasTab: true,
        name: 'tenantInformation.pageTitle',
        path: '/tenants/:tenantId/information',
      },
      {
        component: TenantDisplay,
        exact: true,
        hasTab: true,
        name: 'tenantDisplay.pageTitle',
        path: '/tenants/:tenantId/display',
      },
      {
        component: TenantGroups,
        hasTab: true,
        name: 'tenantGroups.pageTitle',
        path: '/tenants/:tenantId/groups',
      },
      {
        component: TenantAuth,
        exact: true,
        hasTab: true,
        name: 'tenantAuth.pageTitle',
        path: '/tenants/:tenantId/auth',
      },
      {
        component: TenantLicenses,
        hasTab: true,
        name: 'tenantLicenses.pageTitle',
        path: '/tenants/:tenantId/licenses',
      },
      {
        component: TenantFeatureFlags,
        hasTab: true,
        name: 'tenantFeatureFlags.pageTitle',
        path: '/tenants/:tenantId/flags',
      },
    ],
  },
  {
    component: FeatureFlagDefinition,
    path: '/feature-flags/:flagKey',
    routes: [
      {
        component: FeatureFlagDefinitionInformation,
        exact: true,
        name: 'featureFlagDefinitionInfo.globalSettingsTitle',
        path: '/feature-flags/:flagKey',
      },
      {
        component: FeatureFlagDefinitionInformation,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionInfo.globalSettingsTitle',
        path: '/feature-flags/:flagKey/information',
      },
      {
        component: FeatureFlagTenants,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionTenants.pageTitle',
        path: '/feature-flags/:flagKey/tenants',
      },
      {
        component: FeatureFlagRegions,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionRegions.regionsLabel',
        path: '/feature-flags/:flagKey/regions',
      },
      {
        component: FeatureFlagFleets,
        exact: true,
        hasTab: true,
        name: 'featureFlagDefinitionFleets.fleetsLabel',
        path: '/feature-flags/:flagKey/fleets',
      },
    ],
  },
  {
    component: ErrorPage,
    path: '*',
  },
];

export const Routes: React.FC = () => {
  const { loading: configLoading, deployment } = useAppConfigContext();

  if (configLoading || !deployment) {
    return null;
  }

  const routes = deployment.internal ? internalRoutes : externalRoutes;

  return (
    <AppRouteContextProvider routes={routes}>
      <Switch>
        {routes.map((route, i) => (
          <AppRoute key={i} {...route} />
        ))}
      </Switch>
    </AppRouteContextProvider>
  );
};
