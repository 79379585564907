import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Card } from '@bb-ui/react-library/dist/components/Card';
import { CardProps } from '@material-ui/core/Card';

export interface PageCardProps extends Omit<CardProps, 'variant'> {
  variant?: 'default' | 'section';
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  default: {
    marginBottom: '50px',
    padding: theme.spacing(5),
    borderColor: 'transparent',
    minHeight: '250px',
  },
  section: {
    marginBottom: '20px',
    borderRadius: '4px',
  },
}));

export const PageCard: React.FunctionComponent<PageCardProps> = (props) => {
  const { className, variant = 'default', ...other } = props;
  const classes = useStyles(props);

  return (
    <Card className={classnames({ [classes[variant]]: variant }, className)} {...other} />
  );
};

export default PageCard;
