import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import {
  DefaultButton,
  PrimaryButton,
} from '@bb-ui/react-library/dist/components/Button';
import { DialogProps } from '@material-ui/core/Dialog';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { useSnackbar } from 'hooks/useSnackbar';
import { useRestApi } from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';

const styles = (theme: Theme) => createStyles({
  addGroupInput: {
    margin: theme.spacing(2, 0, 1),
  },
});

export const useStyles = makeStyles(styles);

export interface CreateGroupDialogProps extends DialogProps {
  onClose: () => void;
}

export const CreateGroupDialog: React.FunctionComponent<CreateGroupDialogProps> =
  (props) => {
    const { id, open, onClose } = props;
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const {
      clearFailedRequests,
      clearSucceededRequests,
      doPost,
      failedRequests,
      loadingRequests,
      succeededRequests,
    } = useRestApi(apiUrl('sso', 'fndsGroups'), { manual: true });
    const [showErrors, setShowErrors] = React.useState(false);
    const [groupName, setGroupName] = React.useState('');
    const [groupType, setGroupType] = React.useState('');
    const [groupDesc, setGroupDesc] = React.useState('');
    const classes = useStyles(props);

    // Form validation errors. They're only visible if the user has tried to
    // submit the form at least once. One day we may want to limit the types of
    // characters used, etc.

    const groupDescError =
      groupDesc === '' ? t('createGroupDialog.errors.groupDescRequired') : '';
    const groupNameError =
      groupName === '' ? t('createGroupDialog.errors.groupNameRequired') : '';
    const groupTypeError =
      groupType === '' ? t('createGroupDialog.errors.groupTypeRequired') : '';

    // Called if we tried to create or the user cancelled out of the dialog.

    const close = React.useCallback(() => {
      setShowErrors(false);
      onClose();
    }, [onClose]);

    // If we successfully created, then we're done. We assume one request at a time.

    React.useEffect(() => {
      if (succeededRequests.length > 0) {
        enqueueSnackbar(
          t('createGroupDialog.createSucceeded', { name: groupName }),
          { variant: 'success' },
        );
        clearSucceededRequests();
        close();
      }
    }, [
      clearSucceededRequests,
      close,
      enqueueSnackbar,
      groupName,
      succeededRequests.length,
      t,
    ]);

    // If there was an error creating, show a snackbar notification. We close
    // because otherwise the snackbar will appear under the modal overlay, which
    // is difficult to see. We should revisit this.

    React.useEffect(() => {
      if (failedRequests.length > 0) {
        enqueueSnackbar(
          t('createGroupDialog.createFailed', {
            message: failedRequests[0].error.message,
            name: groupName,
          }),
          { variant: 'error' },
        );
        clearFailedRequests();
        close();
      }
    }, [
      clearFailedRequests,
      close,
      enqueueSnackbar,
      failedRequests,
      failedRequests.length,
      groupName,
      t,
    ]);

    // Starts a creation via API attempt.

    function create(event: React.FormEvent<HTMLFormElement>) {
      event.preventDefault();
      setShowErrors(true);

      if (
        groupNameError !== '' ||
        groupTypeError !== '' ||
        groupDescError !== ''
      ) {
        return;
      }

      doPost({ description: groupDesc, name: groupName, type: groupType });
    }

    return (
      <Dialog
        id={id}
        open={open}
        onClose={close}
        aria-labelledby={`${id}-title`}
        aria-describedby={`${id}-description`}
      >
        <DialogTitle onClose={close} id={`${id}-title`}>
          {t('createGroupDialog.title')}
        </DialogTitle>
        <form onSubmit={create} noValidate data-testid="create-group-form">
          <DialogContent>
            <TextField
              className={classes.addGroupInput}
              error={showErrors && groupNameError !== ''}
              helperText={showErrors && groupNameError}
              id="create-group-name-input"
              label={t('createGroupDialog.groupName')}
              onChange={(event) => setGroupName(event.target.value.trim())}
              placeholder={t('createGroupDialog.groupName')}
              fullWidth
              autoFocus
              required
            />
            <TextField
              className={classes.addGroupInput}
              error={showErrors && groupTypeError !== ''}
              helperText={showErrors && groupTypeError}
              id="create-group-type-input"
              label={t('createGroupDialog.groupType')}
              onChange={(event) => setGroupType(event.target.value.trim())}
              placeholder={t('createGroupDialog.groupType')}
              fullWidth
              required
            />
            <TextField
              className={classes.addGroupInput}
              error={showErrors && groupDescError !== ''}
              helperText={showErrors && groupDescError}
              id="create-group-description-input"
              label={t('createGroupDialog.groupDesc')}
              onChange={(event) => setGroupDesc(event.target.value.trim())}
              placeholder={t('createGroupDialog.groupDesc')}
              fullWidth
              multiline
              required
              rows={5}
            />
          </DialogContent>
          <DialogActions>
            <DefaultButton data-testid={`cancel-${id}`} onClick={close}>{t('global.cancel')}</DefaultButton>
            <PrimaryButton data-testid={`submit-${id}`} disabled={loadingRequests} type="submit">
              {t('global.save')}
            </PrimaryButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

export default CreateGroupDialog;
