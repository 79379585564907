import { CardHeader, CircularProgress, Typography } from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { useTenantContext } from 'contexts/TenantContext';
import useRestApi from 'hooks/useRestApi';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { apiUrl } from 'utils/apiUrl';
import { Edit } from '@bb-ui/icons/dist/small/Edit';
import { TenantInformationItem } from './TenantInformationItem';
import { TooltipIconButton } from '../../../components/TooltipIconButton';
import { useAuthorization } from '../../../hooks/useAuthorization';
import { TenantData } from '../../../App.types';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { TenantDialog } from './TenantDialog';

interface TenantReverseLookup {
  /**
   * Primary key of the lookup.
   */
  id: string;
  /**
   * ID of the tenant (probably redundant here).
   */
  tenantId: string;
  /**
   * Type of the lookup, e.g. `collab_lg`.
   */
  type: string;
  /**
   * Value of the lookup.
   */
  value: string;
}

interface TenantReverseLookupResponse {
  count: number;
  results: TenantReverseLookup[];
}

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      marginBottom: '14px',
    },
    section: {
      marginBottom: '45px',
    },
  })
);

export const TenantInformation: React.FC = (props) => {
  const { hasPermission } = useAuthorization();
  const classes = useStyles(props);
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const { t } = useTranslation();
  const {
    error: tenantError,
    loading: tenantLoading,
    tenant,
    fetch: tenantFetch,
  } = useTenantContext();
  const {
    doPatch,
    succeededRequests,
    failedRequests,
    clearSucceededRequests,
    clearFailedRequests,
  } = useRestApi(apiUrl('tenancy', 'tenants'), { manual: true });
  const {
    data: lookupsData,
    error: lookupsError,
    fetch,
    loading: lookupsLoading,
  } = useRestApi<TenantReverseLookupResponse>(
    apiUrl('tenancy', `tenants/reverseLookups?tenantId=${tenant?.id}`),
    {
      manual: true,
    }
  );
  const { enqueueSnackbar } = useSnackbar();

  const canEditVisibility = hasPermission('tenant', 'update');

  function handleOnSave({ parentTenant, description, hostname, region, tenantType }: Partial<TenantData>) {
    doPatch(tenant?.id, { parentTenant, description, hostname, region, tenantType });
    setDialogIsOpen(false);
  }

  React.useEffect(() => {
    if (tenant?.id) {
      // lookupsError will reflect the error condition.
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      fetch().catch(() => {});
    }
  }, [fetch, tenant]);

  React.useEffect(() => {
    if (succeededRequests.length > 0) {
      fetch!();
      tenantFetch!();
      clearSucceededRequests();
      enqueueSnackbar(t('tenantInformation.editSettingsSuccess'), { variant: 'success' });
    }
  }, [succeededRequests, clearSucceededRequests, fetch, tenantFetch, enqueueSnackbar, t]);

  React.useEffect(() => {
    if (failedRequests.length > 0) {
      clearFailedRequests();
      enqueueSnackbar(
        t('tenantInformation.editSettingsError', {
          message: failedRequests[0].error.message,
        }),
        { variant: 'error' }
      );
    }
  }, [failedRequests, clearFailedRequests, enqueueSnackbar, t]);

  let tenantInfo = (
    <CircularProgress
      ariaLabel={t('global.loadingIndicator.loading').toString()}
      data-testid="loading-tenant"
    />
  );

  if (tenantError) {
    tenantInfo = <Typography data-testid="tenant-error">{t('global.fetchError')}</Typography>;
  } else if (!tenantLoading) {
    tenantInfo = (
      <>
        <TenantInformationItem title={t('tenantInformation.foundationsId')} value={tenant?.id} />
        <TenantInformationItem
          title={t('tenantInformation.parentTenantId')}
          value={tenant?.parentTenant}
        />
        <TenantInformationItem
          title={t('tenantInformation.description')}
          value={tenant?.description}
        />
        <TenantInformationItem title={t('tenantInformation.hostname')} value={tenant?.hostname} />
        <TenantInformationItem title={t('tenantInformation.region')} value={tenant?.region} />
        <TenantInformationItem title={t('tenantInformation.tenantType')} value={tenant?.tenantType} />
      </>
    );
  }

  let lookupContent = (
    <CircularProgress
      ariaLabel={t('global.loadingIndicator.loading').toString()}
      data-testid="loading-lookups"
    />
  );

  if (lookupsError) {
    lookupContent = <Typography data-testid="lookups-error">{t('global.fetchError')}</Typography>;
  } else if (!lookupsLoading && lookupsData) {
    if (lookupsData.results.length === 0) {
      lookupContent = <Typography>{t('tenantInformation.noAddlIdentities')}</Typography>;
    } else {
      lookupContent = (
        <>
          {lookupsData.results.map((lookup: TenantReverseLookup) => (
            <TenantInformationItem title={lookup.type} value={lookup.value} key={lookup.type} />
          ))}
        </>
      );
    }
  }

  return (
    <div data-testid="tenant-information-page">
      <div className={classes.section}>
        <CardHeader
          title={t('tenantInformation.pageTitle')}
          titleTypographyProps={{
            color: 'textSecondary',
            component: 'h3' as any,
            variant: 'h4',
          }}
          action={
            canEditVisibility && (
              <TooltipIconButton
                aria-haspopup="dialog"
                label={t('global.edit')}
                onClick={() => setDialogIsOpen(true)}
              >
                <Edit />
              </TooltipIconButton>
            )
          }
          className={classes.heading}
        />
        {tenantInfo}
      </div>
      <div className={classes.section}>
        <Typography className={classes.heading} component="h2" variant="h4">
          {t('tenantInformation.addlIdentities')}
        </Typography>
        {lookupContent}
      </div>
      <TenantDialog
        onClose={() => setDialogIsOpen(false)}
        onSave={handleOnSave}
        open={dialogIsOpen}
        tenant={tenant}
      />
    </div>
  );
};
