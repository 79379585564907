import * as React from 'react';
import classnames from 'classnames';
import { makeStyles, createStyles } from '@material-ui/core';
import { Box } from '@bb-ui/react-library/dist/components/Box';
import { BoxProps } from '@material-ui/core/Box';

export const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    maxWidth: '1000px',
  },
}));

export const ContentWrap: React.FunctionComponent<BoxProps> = (props) => {
  const { className, ...other } = props;
  const classes = useStyles(props);

  return (
    <Box
      className={classnames(classes.root, className)}
      marginX="auto"
      {...other}
    />
  );
};

export default ContentWrap;
