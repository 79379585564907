import {
  FeatureFlagDefinitionData,
  FeatureFlagPossibleValuesOption,
  FeatureFlagVisibility,
  putTenantsFlagValue,
} from '@bb-config-ui/feature-flags';
import { Information } from '@bb-ui/icons/dist/small';
import {
  DefaultButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  OutlineButton,
  PrimaryButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@bb-ui/react-library';
import {
  FieldsetProps,
  LegendProps,
} from '@bb-ui/react-library/dist/components/RadioGroup/RadioGroup.types';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useAuthContext } from 'contexts/AuthContext';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FeatureFlagValuesFollowupDialog } from '../FeatureFlagGeneral/FeatureFlagValuesFollowupDialog';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editHeadingText: {
      marginRight: theme.spacing(1),
    },
    radioButton: {
      marginLeft: theme.spacing(-1),
    },
    dialogTitle: {
      alignItems: 'start',
    },
    sectionTitle: {
      display: 'inline-flex',
    },
    metadataInfoIcon: {
      position: 'relative',
      top: theme.spacing(0.5),
    },
  })
);

interface Fleet {
  id: string;
  name: string;
  region: string;
  visibility?: boolean;
  tenants?: string[];
}

export interface FeatureFlagFleetsDialogProps {
  isDialogOpen: boolean;
  dialogToggle: (value: boolean) => void;
  flagDefinition: FeatureFlagDefinitionData;
  updateFeatureFlagDefinition: (
    flagKey: string,
    defaultValue?: string,
    clearFlagValues?: boolean,
    visibility?: FeatureFlagVisibility
  ) => void;
  selectedFleet: Fleet;
  flagName: string;
  batchUpdateState: (isLoading?: boolean, response?: any, error?: Error) => void;
}

/* Current hacks that need to be addressed in the future:

 1. Right now the logic for preventing the user from editing the visibility in the dialog when visibility = true, or region that houses the fleet is enabled
 in the region criteria, is based on hardcoded region values in the fleet definitions (fleets.json)
*/
export const FeatureFlagFleetsDialog: React.FC<FeatureFlagFleetsDialogProps> = (props) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { idToken } = useAuthContext();
  const Fieldset = FormControl as React.ForwardRefExoticComponent<FieldsetProps>;
  const Legend = FormLabel as React.ForwardRefExoticComponent<LegendProps>;
  const {
    flagDefinition,
    isDialogOpen,
    dialogToggle,
    updateFeatureFlagDefinition,
    selectedFleet,
    flagName,
    batchUpdateState,
  } = props;
  const { flagKey, visibility } = flagDefinition;
  const [currentFlagValue, setValue] = React.useState<string>();
  const [currentAdminPermission, setCurrentAdminPermission] = React.useState<string>();
  const isEditingDisabled =
    visibility.visible || visibility.criteria?.regions?.includes(selectedFleet.region);
  const [batchUpdateLoading, setBatchUpdateLoading] = React.useState<boolean>(false);

  function getNewFleetVisibility(
    flagVisibility: FeatureFlagVisibility,
    fleetId: string,
    isVisible: boolean
  ) {
    const tenants = flagVisibility?.criteria?.tenants ?? [];
    const regions = flagVisibility?.criteria?.regions ?? [];
    let fleets = flagVisibility?.criteria?.fleets ?? [];
    fleets = [...fleets.filter((fleet) => fleet !== fleetId)];
    if (isVisible) {
      fleets.push(fleetId);
    }
    const newVisibility: FeatureFlagVisibility = {
      visible: flagVisibility.visible,
      criteria: {
        ...(tenants.length && { tenants }),
        ...(regions.length && { regions }),
        ...(fleets.length && { fleets }),
      },
    };
    return newVisibility;
  }

  function handleClearValues() {
    setValue(undefined);
    setCurrentAdminPermission(undefined);
  }

  function handleOnClose() {
    handleClearValues();
    dialogToggle(false);
  }

  function handleOnChangeFlagStatus(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
  }

  function handleOnChangeAdminPermissions(event: React.ChangeEvent<HTMLInputElement>) {
    setCurrentAdminPermission(event.target.value);
  }

  function handleOnSubmit() {
    let locked;
    let newVisibility: FeatureFlagVisibility | undefined;
    if (currentAdminPermission && !isEditingDisabled) {
      switch (currentAdminPermission) {
        case 'None': {
          newVisibility = getNewFleetVisibility(visibility, selectedFleet.id, false);
          break;
        }
        case 'CanView': {
          locked = true;
          newVisibility = getNewFleetVisibility(visibility, selectedFleet.id, true);
          break;
        }
        case 'CanEdit': {
          locked = false;
          newVisibility = getNewFleetVisibility(visibility, selectedFleet.id, true);
          break;
        }
        default: {
          throw new Error(`Unexpected target value ${currentAdminPermission}`);
        }
      }
    }
    if ((currentFlagValue || locked !== undefined) && selectedFleet.tenants) {
      setBatchUpdateLoading(true);
      batchUpdateState(true);
      putTenantsFlagValue(
        idToken as string,
        flagKey,
        selectedFleet.region,
        selectedFleet.tenants,
        'Tenant',
        currentFlagValue,
        locked
      )
        .then((response) => {
          batchUpdateState(false, response);
        })
        .catch((error) => {
          batchUpdateState(false, undefined, error);
        })
        .finally(() => {
          setBatchUpdateLoading(false);
        });
    }
    if (newVisibility) {
      updateFeatureFlagDefinition(flagKey, undefined, undefined, newVisibility);
    }
    handleClearValues();
  }

  let flagValueOptions;
  if (flagDefinition.possibleValues.type.toLowerCase() === 'list') {
    flagValueOptions = (
      <>
        {flagDefinition.possibleValues.options?.map((option: FeatureFlagPossibleValuesOption) => (
          <FormControlLabel
            key={`${option.value}-option`}
            value={option.value.toLowerCase()}
            className={classes.radioButton}
            checked={currentFlagValue === option.value.toLowerCase()}
            control={<Radio />}
            label={option.value.toLowerCase()}
          />
        ))}
      </>
    );
  } else {
    flagValueOptions = (
      <>
        <FormControlLabel
          className={classes.radioButton}
          checked={currentFlagValue === 'false'}
          value="false"
          control={<Radio />}
          label={t('global.off')}
        />
        <FormControlLabel
          className={classes.radioButton}
          checked={currentFlagValue === 'true'}
          value="true"
          control={<Radio />}
          label={t('global.on')}
        />
      </>
    );
  }

  return (
    <Dialog open={isDialogOpen} onClose={handleOnClose} data-testid="feature-flags-fleet-dialogue">
      <FeatureFlagValuesFollowupDialog isDialogOpen={batchUpdateLoading} />
      <DialogTitle
        className={classes.dialogTitle}
        onClose={handleOnClose}
        id="feature-flags-fleet-dialog-title"
      >
        {t('featureFlagGeneral.manageSettings', { flagName, item: selectedFleet.name })}
      </DialogTitle>
      <DialogContent id="feature-flags-fleet-dialogue-content">
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <div className={classes.sectionTitle}>
            <Typography
              className={classes.editHeadingText}
              variant="h4"
              id="feature-flags-fleet-dialogue-feature-status-subtitle"
            >
              {t('tenantFeatureFlags.featureStatus')}
            </Typography>
            <Tooltip
              title={t('featureFlagGeneral.dialogTooltip', {
                flagProperty: 'Feature Status',
                criteria: 'fleet',
              }).toString()}
              placement="right"
              data-testid="fleets-dialog-feature-status"
            >
              <Information className={classes.metadataInfoIcon} />
            </Tooltip>
          </div>
          <RadioGroup
            hasCustomLegend
            id="feature-flags-fleet-dialogue-feature-status-options"
            name="fleet-edit-feature-status"
            onChange={handleOnChangeFlagStatus}
          >
            {flagValueOptions}
          </RadioGroup>
        </Fieldset>
        <Fieldset component="fieldset" fullWidth={true}>
          <Legend component="legend" />
          <div className={classes.sectionTitle}>
            <Typography
              className={classes.editHeadingText}
              variant="h4"
              id="feature-flags-fleet-dialogue-flag-visibility-subtitle"
            >
              {t('featureFlagGeneral.clientAdminPermissions')}
            </Typography>
            <Tooltip
              title={t('featureFlagGeneral.dialogTooltip', {
                flagProperty: 'Client Admin Permissions',
                criteria: 'fleet',
              }).toString()}
              placement="right"
              data-testid="fleets-dialog-client-admin-permissions"
            >
              <Information className={classes.metadataInfoIcon} />
            </Tooltip>
          </div>
          <RadioGroup
            hasCustomLegend
            id="feature-flags-fleet-dialogue-flag-visibility-options"
            name="fleet-edit-visibility"
            onChange={handleOnChangeAdminPermissions}
          >
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'None'}
              disabled={isEditingDisabled}
              value="None"
              control={<Radio />}
              label={t('featureFlagGeneral.none')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'CanView'}
              value="CanView"
              control={<Radio />}
              label={t('featureFlagGeneral.canView')}
            />
            <FormControlLabel
              className={classes.radioButton}
              checked={currentAdminPermission === 'CanEdit'}
              value="CanEdit"
              control={<Radio />}
              label={t('featureFlagGeneral.canEdit')}
            />
          </RadioGroup>
        </Fieldset>
      </DialogContent>
      <DialogActions
        id="feature-flags-fleet-dialogue-actions"
        tertiaryContent={
          <OutlineButton onClick={handleClearValues}> {t('global.reset')}</OutlineButton>
        }
      >
        <DefaultButton id="feature-flags-fleet-dialogue-cancel-btn" onClick={handleOnClose}>
          {t('global.cancel')}
        </DefaultButton>
        <PrimaryButton
          id="feature-flags-fleet-dialogue-submit-btn"
          data-testid="submit-button"
          onClick={handleOnSubmit}
          disabled={currentAdminPermission === undefined && currentFlagValue === undefined}
        >
          {t('global.save')}
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
