import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { AppBar } from '@bb-ui/react-library/dist/components/AppBar';
import { Toolbar } from '@bb-ui/react-library/dist/components/Toolbar';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Logo } from 'components/Logo';
import { HeaderUserMenu } from './HeaderUserMenu';

interface HeaderProps {
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    gridArea: 'header',
    boxShadow: '0 0 3px 0 rgba(0,0,0, 0.1)',
    backgroundColor: theme.palette.common.black,
  },
  headerContent: {
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  // TODO: revise when we have designs for smaller breakpoints
  // right now this will overloap other header content on smaller screens
  subTitle: {
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
}));

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const classes = useStyles(props);

  return (
    <AppBar className={classes.header} position="sticky" color="inherit" data-testid="app-header">
      <Toolbar className={classes.headerContent}>
        <Logo />
        <Typography className={classes.subTitle} variant="h2" component="h1">Foundations</Typography>
        <HeaderUserMenu />
      </Toolbar>
    </AppBar>
  );
};
