import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DialogProps } from '@material-ui/core/Dialog';
import { DefaultButton, PrimaryButton } from '@bb-ui/react-library/dist/components/Button';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import { TextField } from '@bb-ui/react-library/dist/components/TextField';
import { RadioGroup } from '@bb-ui/react-library/dist/components/RadioGroup';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import { Radio } from '@bb-ui/react-library/dist/components/Radio';
import { FormControl } from '@bb-ui/react-library/dist/components/FormControl';
import { FormHelperText } from '@bb-ui/react-library/dist/components/FormHelperText';
import { Input } from '@bb-ui/react-library/dist/components/Input';
import { InputLabel } from '@bb-ui/react-library/dist/components/InputLabel';
import { FileUpload } from 'components/FileUpload';
import { useFileReader } from 'hooks/useFileReader';
import { SamlProvisionersPost } from './TenantIdps.context';

const styles = (theme: Theme) => createStyles({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.spacing(2),
  },
  metadataOption: {
    margin: theme.spacing(0, 1, 1, -1),
  },
});

export const useStyles = makeStyles(styles);

export interface ConnectSamlIdpDialogProps extends DialogProps {
  onClose: () => void;
  onPost: (body: SamlProvisionersPost) => void;
}

type MetadataType = 'URL' | 'File';
export const SAML_METADATA_MAX_FILESIZE = 1024 * 1024; // 1MB size limit

export const ConnectSamlIdpDialog: React.FunctionComponent<ConnectSamlIdpDialogProps> = (props) => {
  const { id, open, onPost, onClose } = props;
  const { t } = useTranslation();
  const [showErrors, setShowErrors] = React.useState(false);
  const [displayName, setDisplayName] = React.useState('');
  const [userAttribute, setUserAttribute] = React.useState('');
  const [groupAttribute, setGroupAttribute] = React.useState('');

  const [metadataType, setMetadataType] = React.useState<MetadataType>('URL');
  const [metadataUrl, setMetadataUrl] = React.useState('');
  const [metadataFile, setMetadataFile] = React.useState<File | undefined>();
  const {
    data: metadataXml,
    error: metadataReadError,
    loading: metadataLoading,
  } = useFileReader(metadataFile);

  const classes = useStyles(props);

  // Form validation errors. They're only visible if the user has tried to
  // submit the form at least once.

  const displayNameError = (displayName === '') ? t('tenantConnectSamlIdpsDialog.errors.displayNameRequired') : '';
  const userAttributeError = (userAttribute === '') ? t('tenantConnectSamlIdpsDialog.errors.userAttributeRequired') : '';
  const groupAttributeError = (groupAttribute === '') ? t('tenantConnectSamlIdpsDialog.errors.groupAttributeRequired') : '';

  const getMetadataError = () => {
    if (metadataType === 'URL') {
      if (metadataUrl === '') {
        return t('tenantConnectSamlIdpsDialog.errors.metadataRequired');
      }
      if (!/^(http|https):\/\/.*$/i.test(metadataUrl)) {
        return t('tenantConnectSamlIdpsDialog.errors.metadataUrlHttpOnly');
      }
    } else { // File
      if (!metadataFile) {
        return t('tenantConnectSamlIdpsDialog.errors.metadataRequired');
      }
      if (metadataFile!.size > SAML_METADATA_MAX_FILESIZE) {
        return t('tenantConnectSamlIdpsDialog.errors.metadataFileTooLarge');
      }
      if (metadataReadError) {
        return t('tenantConnectSamlIdpsDialog.errors.metadataFileReadFailed', { message: metadataReadError.message });
      }
    }
    return '';
  };

  const metadataError = getMetadataError();
  const metadataHelperText = metadataType === 'URL' ?
    t('tenantConnectSamlIdpsDialog.metadataUrlHint') :
    t('tenantConnectSamlIdpsDialog.metadataFileHint');

  // Called if we tried to create or the user cancelled out of the dialog.

  const close = React.useCallback(() => {
    setShowErrors(false);
    onClose();
  }, [onClose]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if ([displayNameError, userAttributeError, groupAttributeError, metadataError].find(error => error !== '')) {
      setShowErrors(true);
    } else {
      onPost({
        displayName,
        authBroker: 'Auth0',
        authBrokerConnectionType: 'SAML',
        mapping: { user_id: userAttribute, groups: groupAttribute },
        ...(metadataType === 'URL' ? { metadataUrl } : { metadataXml: metadataXml as string }),
      });
    }
  };

  return (
    <Dialog
      id={id}
      open={open}
      onClose={close}
      aria-labelledby={`${id}-title`}
      aria-describedby={`${id}-description`}
      maxWidth="md"
    >
      <DialogTitle onClose={close} id={`${id}-title`}>{t('tenantConnectSamlIdpsDialog.title')}</DialogTitle>
      <form onSubmit={handleSubmit} noValidate data-testid="saml-connection-form">
        <DialogContent className={classes.content}>
          <TextField
            error={showErrors && displayNameError !== ''}
            helperText={showErrors && displayNameError}
            id="saml-display-name-input"
            label={t('tenantConnectSamlIdpsDialog.displayName')}
            onChange={(event) => setDisplayName(event.target.value)}
            value={displayName}
            placeholder={t('tenantConnectSamlIdpsDialog.displayNamePlaceholder')}
            fullWidth
            autoFocus
            required
          />
          <TextField
            error={showErrors && userAttributeError !== ''}
            helperText={showErrors && userAttributeError}
            id="saml-user-attribute-input"
            label={t('tenantConnectSamlIdpsDialog.userAttribute')}
            onChange={(event) => setUserAttribute(event.target.value)}
            value={userAttribute}
            placeholder={t('tenantConnectSamlIdpsDialog.userAttributePlaceholder')}
            fullWidth
            required
          />
          <TextField
            error={showErrors && groupAttributeError !== ''}
            helperText={showErrors && groupAttributeError}
            id="saml-group-attribute-input"
            label={t('tenantConnectSamlIdpsDialog.groupAttribute')}
            onChange={(event) => setGroupAttribute(event.target.value)}
            value={groupAttribute}
            placeholder={t('tenantConnectSamlIdpsDialog.groupAttributePlaceholder')}
            fullWidth
            required
          />
          <div>
            <FormControl required>
              <InputLabel htmlFor="saml-metadata-radio-group">
                {t('tenantConnectSamlIdpsDialog.metadata')}
              </InputLabel>
            </FormControl>
            <RadioGroup
              id="saml-metadata-radio-group"
              row
              name="metadataType"
              hasCustomLegend
              isRequired
              requiredLabel={t('tenantConnectSamlIdpsDialog.requiredAriaLabel')}
              onChange={event => setMetadataType(event.target.value as MetadataType)}
            >
              <FormControlLabel
                value="URL"
                className={classes.metadataOption}
                checked={metadataType === 'URL'}
                htmlFor="saml-metadata-url-radio"
                control={<Radio id="saml-metadata-url-radio" />}
                label={t('tenantConnectSamlIdpsDialog.metadataUrl')}
              />
              <FormControlLabel
                value="File"
                className={classes.metadataOption}
                checked={metadataType === 'File'}
                htmlFor="saml-metadata-file-radio"
                control={<Radio id="saml-metadata-file-radio" />}
                label={t('tenantConnectSamlIdpsDialog.metadataFile')}
              />
            </RadioGroup>
            <FormControl
              error={showErrors && metadataError !== ''}
              fullWidth
            >
              {metadataType === 'URL' ?
                <Input
                  id="saml-metadata-url-input"
                  aria-label={t('tenantConnectSamlIdpsDialog.metadataUrlAddressAriaLabel')}
                  placeholder={t('tenantConnectSamlIdpsDialog.metadataUrlAddress')}
                  onChange={(event) => setMetadataUrl(event.target.value)}
                  value={metadataUrl}
                /> :
                <FileUpload
                  id="saml-metadata-file-input"
                  accept="application/xml,text/xml,.xml"
                  prompt={t('tenantConnectSamlIdpsDialog.metadataFilePrompt')}
                  removeLabel={t('tenantConnectSamlIdpsDialog.metadataFileRemoveAriaLabel')}
                  onChange={setMetadataFile}
                />
              }
              <FormHelperText>
                {(showErrors && metadataError !== '') ? metadataError : metadataHelperText}
              </FormHelperText>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <DefaultButton data-testid={`cancel-${id}`} onClick={close}>{t('global.cancel')}</DefaultButton>
          <PrimaryButton data-testid={`submit-${id}`} type="submit" disabled={metadataLoading}>
            {t('tenantConnectSamlIdpsDialog.startConnection')}
          </PrimaryButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ConnectSamlIdpDialog;
