import { createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { getColor } from '@bb-ui/react-library/dist/components/styles';

// TODO: nav toggle for small breakpoint
export const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    display: 'grid',
    justifyContent: 'center',
    overflowY: 'auto',
    overflowX: 'hidden',
    // TODO: (after nav toggle) remove navigation
    gridTemplateAreas: `"header"
                        "page-header"
                        "main"`,
    // TODO: (after nav toggle) remove auto
    gridTemplateRows: `64px
                       auto
                       1fr`,
    gridTemplateColumns: '100%',
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `"header"
                          "page-header"
                          "main"`,
      gridTemplateRows: `64px
                         206px
                         1fr`,
      gridTemplateColumns: '1fr',
    },
    backgroundColor: getColor('background.b3', theme),
  },
  contentWrap: {
    maxWidth: '1000px',
  },
});
