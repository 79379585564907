import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PageTemplate } from 'components/PageTemplate';
import { Subroutes, SubroutesProps } from 'routes/Subroutes';
import { TenantContextProvider, TenantContext, TenantContextProps } from 'contexts/TenantContext/TenantContext';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { TenantSupportLogin } from "components/TenantSupportLogin";

export interface TenantProps extends SubroutesProps {}

export const Tenant: React.FunctionComponent<TenantProps> = ({ routes }) => {
  const { tenantId } = useParams<{ tenantId: string }>();
  const loaded = React.useRef(false);
  const { t } = useTranslation();

  return (
    <TenantContextProvider tenantId={tenantId!}>
      <TenantContext.Consumer>
        {(value: TenantContextProps) => {
          const { loading, tenant, error } = value;

          if (!loaded.current && !loading) {
            /**
             * Track loading to prevent re-rendering page template on tenant update.
             */
            loaded.current = true;
          }

          return (
            <>
              {!loaded.current && loading &&
                <LoadingIndicator
                  theme="dark"
                  variant="fill-window"
                  data-testid="loading-tenant"
                />
              }
              {loaded.current &&
                <PageTemplate
                  breadcrumbProps={tenant && { replaceText: { 'tenantProfile.pageTitle': tenant.name! } }}
                  showTabs
                  title={tenant ? tenant.name! : t('tenant.notFoundTitle')}
                  errorMessage={error && {
                    title: t('tenant.doesNotExist'),
                    message: error.message,
                  }}
                  headerControl={<TenantSupportLogin />}
                >
                  <Subroutes routes={routes} />
                </PageTemplate>
              }
            </>
          );
        }}
      </TenantContext.Consumer>
    </TenantContextProvider>
  );
};
