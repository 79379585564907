/**
 * A component to allow the user to select a file on their computer, either by
 * clicking a button or by dragging and dropping. For now, this only accepts a
 * single file.
 *
 * @see https://material-ui.com/components/buttons/#upload-button
 */

import * as React from 'react';
import attrAccept from 'attr-accept';
import { Attachment } from '@bb-ui/icons/dist/small/Attachment';
import { ButtonBase } from '@bb-ui/react-library/dist/components/ButtonBase';
import { HideOffScreen } from '@bb-ui/react-library/dist/components/HideOffScreen';
import { IconButton } from '@bb-ui/react-library/dist/components/IconButton';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Trash } from '@bb-ui/icons/dist/small/Trash';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { UploadFile } from '@bb-ui/icons/dist/small/UploadFile';

export interface FileUploadProps {
  /**
   * File types for the input to accept.
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
   */
  accept?: string;

  /** Icon to display beside the file name once a file has been selected. Defaults to <Attachment /> */
  fileIcon?: React.Component;

  /** DOM id for the input. */
  id: string;

  /** Callback triggered when the user chooses a file. */
  onChange?: (file?: File) => void;

  /** Prompt shown to the user when no file has been selected. */
  prompt: string;

  /** Icon to display beside the prompt when no file has been selected. Defaults to <UploadFile />.  */
  promptIcon?: React.Component;

  /** Icon used by the button to clear a file selection. Defaults to <Trash />. */
  removeIcon?: React.Component;

  /** ARIA label for the remove button. */
  removeLabel: string;
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  fileContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.b5}`,
    display: 'inline-flex',
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  fileName: {
    alignItems: 'center',
    display: 'inline-flex',
    height: '100%',
    marginRight: theme.spacing(1),
  },
  labelIcon: {
    alignItems: 'center',
    display: 'inline-flex',
    height: '100%',
    justifyContent: 'center',
    marginRight: theme.spacing(0.5),
  },
  root: {
    display: 'inline-block',
    height: '60px',
  },
  uploadButton: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    border: `2px dashed ${theme.palette.background.b5}`,
    display: 'inline-flex',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export const FileUpload: React.FunctionComponent<FileUploadProps> = (props) => {
  const { accept, fileIcon, id, onChange, prompt, promptIcon, removeIcon, removeLabel } = props;
  const classes = useStyles(props);
  const [file, setFile] = React.useState<File>();
  const uploadInput = React.useRef<HTMLInputElement>(null);

  const LooseButtonBase = ButtonBase as any; // To allow use of the component prop

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (files?.[0]) {
      setFile(files[0]);

      if (onChange) {
        onChange(files[0]);
      }
    }
  };

  const handleFileDragOver = (event: React.DragEvent) => {
    // Unless prevented, browsers will reset the drag event entirely here. See
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/dragover_event
    event.preventDefault();
  };

  const handleFileDrop = (event: React.DragEvent) => {
    const { files } = event.dataTransfer;

    event.preventDefault();

    if (files[0]) {
      if (accept && !attrAccept({ name: files[0].name, type: files[0].type }, accept)) {
        return;
      }

      setFile(files[0]);

      if (onChange) {
        onChange(files[0]);
      }
    }
  };

  const handleRemove = () => {
    uploadInput.current!.value = '';
    setFile(undefined);

    if (onChange) {
      onChange();
    }
  };

  return (
    <span className={classes.root} onDragOver={handleFileDragOver} onDrop={handleFileDrop}>
      <HideOffScreen><input accept={accept} id={id} onChange={handleChange} ref={uploadInput} type="file" /></HideOffScreen>
      {file ?
        <div className={classes.fileContainer}>
          <span className={classes.fileName}>
            <span className={classes.labelIcon}>{fileIcon || <Attachment />}</span>
            <Typography component="span" variant="h4">{file.name}</Typography>
          </span>
          <IconButton aria-label={removeLabel} onClick={handleRemove}>
            {removeIcon || <Trash />}
          </IconButton>
        </div> :
        <label htmlFor={id}>
          <LooseButtonBase className={classes.uploadButton} component="span">
            <span className={classes.labelIcon}>{promptIcon || <UploadFile />}</span>
            <Typography component="span" variant="h4">{prompt}</Typography>
          </LooseButtonBase>
        </label>}
    </span>);
};
