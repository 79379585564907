import * as React from 'react';
import useRestApi from 'hooks/useRestApi';
import { apiUrl } from 'utils/apiUrl';
import {
  OnSortChangedParams,
  SortDirection,
} from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import {
  SortableTableHeaderCell,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@bb-ui/react-library';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { ErrorMessage } from 'components/ErrorMessage';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { PaginatedTable } from 'components/PaginatedTable';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import {
  useFeatureFlagDefinitions,
  FeatureFlagDefinitionData,
  IFeatureFlagValueUpdateStateHandler,
  IFeatureFlagDefinitionUpdateStateHandler,
  IFeatureFlagLocalizedLabel,
} from '@bb-config-ui/feature-flags';
import { orderBy } from 'lodash';
import { useAppConfigContext } from 'contexts/AppConfigContext';
import { useAuthContext } from 'contexts/AuthContext';
import { useSnackbar } from 'hooks/useSnackbar';
import { TenantData } from 'App.types';
import { FeatureFlagTenantEditDialog } from './FeatureFlagTenantEditDialog';
import { FeatureFlagTenantsTableRow } from './FeatureFlagTenantsTableRow';

interface IFeatureFlagValueData {
  flagKey: string;
  scope: string;
  value: string;
  locked: boolean;
  overridden: boolean;
  tenantId: string;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      marginBottom: theme.spacing(1),
      display: 'inline-flex',
      width: '100%',
    },
  })
);

export const FeatureFlagTenants: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation('home');
  const localLanguage = i18n.language ? i18n.language.replace('-', '_') : 'en_US';
  const classes = useStyles(props);
  const { flagKey } = useParams<{ flagKey: string }>();
  const { idToken } = useAuthContext();
  const tenantRegions = useAppConfigContext().tenantSupportedRegions ?? [];
  const featureFlagRegions = useAppConfigContext().featureFlagSupportedRegions ?? [];
  const { enqueueSnackbar } = useSnackbar();

  const [flagDefinition, setFlagDefinition] = React.useState<FeatureFlagDefinitionData>();
  const [currentTenant, setCurrentTenant] = React.useState<TenantData>();
  const [flagValue, setFlagValue] = React.useState<IFeatureFlagValueData>();
  const [enableSnackBar, setEnableSnackBar] = React.useState(true);
  const [loadingFeatureUpdate, setLoadingFeatureUpdate] = React.useState(false);
  const [isDialogOpen, toggleDialogOpen] = React.useState(false);

  let content: React.ReactElement = <></>;

  const {
    data,
    error: errorTenant,
    loading: loadingTenant,
  } = useRestApi(apiUrl('tenancy', 'tenants/searchable'));
  const tenants = React.useMemo<TenantData[]>(() => {
    // Filter Tenants based on Tenant supported regions for this deployment
    const allTenantsData: TenantData[] = data?.results ?? [];
    const filteredTenantsData: TenantData[] = [];
    allTenantsData.forEach((tenant) => {
      if (
        !tenant.region ||
        (tenantRegions.includes(tenant.region) && featureFlagRegions.includes(tenant.region))
      ) {
        filteredTenantsData.push(tenant);
      }
    });
    return filteredTenantsData;
  }, [data, tenantRegions, featureFlagRegions]);
  const {
    flagDefinitions,
    error: errorFFD,
    loading: loadingFFD,
  } = useFeatureFlagDefinitions({ accessToken: idToken as string });

  // This is used to localize the Feature Flag name filtering by local language or by default in "en_US"
  const findLabelByLocale = React.useCallback(
    (labels: IFeatureFlagLocalizedLabel[]) => {
      let labelWithLanguage = labels?.find((data) => data.locale === localLanguage);

      if (!labelWithLanguage) {
        labelWithLanguage = labels?.find((data) => data.locale === 'en_US');
      }

      return labelWithLanguage ? labelWithLanguage?.label : '';
    },
    [localLanguage]
  );

  const search =
    (filter: string) =>
    ({ name, id }: TenantData) => {
      let nameId = name.toLowerCase().includes(filter.toLowerCase());
      if (!nameId) {
        nameId = id.toLowerCase().includes(filter.toLowerCase());
      }
      return nameId ?? false;
    };

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`featureFlagGeneral.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', { columnLabel, orderLabel });
  };

  const [sortParams, setSortParams] = React.useState<Partial<OnSortChangedParams>>({});
  const sortedTenants = React.useMemo(() => {
    const { sortColumnId, sortDirection } = sortParams;
    return sortColumnId ? orderBy(tenants, [sortColumnId], [sortDirection ?? true]) : tenants;
  }, [tenants, sortParams]);

  React.useEffect(() => {
    const flagDefinition: FeatureFlagDefinitionData = flagDefinitions?.find(
      (el: FeatureFlagDefinitionData) => el?.flagKey === flagKey
    ) as FeatureFlagDefinitionData;
    setFlagDefinition(flagDefinition);
  }, [flagDefinitions, flagKey]);

  // Add snackbar in response of changes done in dialog, Also update tenantFeatureFlags state in order
  // to reflect the most updated data finally update loadingFeatureUpdate state in order to show a loading
  // icon for the feature that is being updated.
  const dialogResponse = React.useCallback(
    (
      featureFlagValueUpdateStateHandler: IFeatureFlagValueUpdateStateHandler,
      featureFlagDefinitionUpdateStateHandler: IFeatureFlagDefinitionUpdateStateHandler,
      clearUpdateErrors,
      clearUpdateResponses
    ) => {
      if (
        !isDialogOpen &&
        featureFlagValueUpdateStateHandler &&
        featureFlagDefinitionUpdateStateHandler
      ) {
        const flagDefinitionResponse =
          featureFlagDefinitionUpdateStateHandler?.flagDefinitionResponse;
        const flagValueResponse = featureFlagValueUpdateStateHandler?.flagValueResponse;

        if (
          featureFlagDefinitionUpdateStateHandler?.flagDefinitionError ||
          featureFlagValueUpdateStateHandler.flagValueError
        ) {
          enqueueSnackbar(t('featureFlagGeneral.updateFeatureFlagFail'), { variant: 'error' });
          setLoadingFeatureUpdate(false);
          clearUpdateErrors();
        } else if (
          featureFlagDefinitionUpdateStateHandler.flagDefinitionLoading ||
          featureFlagValueUpdateStateHandler.flagValueLoading
        ) {
          setLoadingFeatureUpdate(true);
          setEnableSnackBar(true);
        } else if ((flagValueResponse || flagDefinitionResponse) && enableSnackBar) {
          setLoadingFeatureUpdate(false);
          enqueueSnackbar(
            t('featureFlagGeneral.manageSettingsSuccess', {
              flagName: findLabelByLocale(flagDefinition?.labels as IFeatureFlagLocalizedLabel[]),
              item: currentTenant?.name,
            }),
            { variant: 'info' }
          );
          if (flagValueResponse) {
            setEnableSnackBar(false);
            setFlagValue(flagValueResponse.data);
          }

          if (flagDefinitionResponse) {
            setEnableSnackBar(false);
            setFlagDefinition(flagDefinitionResponse.data);
          }

          clearUpdateResponses();
        }
      }
    },
    [
      isDialogOpen,
      currentTenant,
      enableSnackBar,
      flagDefinition,
      findLabelByLocale,
      enqueueSnackbar,
      t,
    ]
  );

  if (errorFFD || errorTenant) {
    content = (
      <ErrorMessage
        data-testid="fnds-feature-flag-tenants-list-error"
        title={t('featureFlagDefinitionTenants.loadError')}
        message={errorFFD?.message ?? errorTenant?.message}
        variant="block"
      />
    );
  } else if (loadingFFD || loadingTenant) {
    content = <LoadingIndicator data-testid="fnds-feature-flag-tenants-list-init" />;
  } else if (flagDefinitions && tenants && flagDefinitions.length > 0) {
    content = (
      <div data-testid="feature-flags-definition-tenants-data">
        <PaginatedTable
          onSortChanged={(sortParams) => {
            setSortParams(sortParams);
            return true;
          }}
          getSortChangedAriaMessage={getAriaSortMessage}
          searchBoxProps={{ label: t('featureFlagDefinitionTenants.searchLabel') }}
          sortedData={sortedTenants}
          search={search}
          noMatchesMessage={(searchExpression) =>
            t('featureFlagDefinitionTenants.noMatch', { searchExpression })
          }
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  id="feature-flag-tenants-table-header-tenant_name"
                  columnId="name"
                  tooltip={t('featureFlagDefinitionTenants.sortByTenantName')}
                >
                  {t('featureFlagGeneral.name')}
                </SortableTableHeaderCell>
                <TableCell id="feature-flag-tenants-table-header-tenant_client_admin_permissions">
                  {t('tenantFeatureFlags.featureStatus')}
                </TableCell>
                <TableCell
                  id="feature-flag-tenants-table-header-tenant_client_admin_permissions"
                  colSpan={2}
                >
                  {t('featureFlagGeneral.clientAdminPermissions')}
                </TableCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={(data: TenantData, index) => (
            <FeatureFlagTenantsTableRow
              key={index}
              index={index}
              tenant={data}
              updatedFlagValue={flagValue}
              setFlagValue={setFlagValue}
              flagDefinition={flagDefinition!}
              loadingFeatureUpdate={loadingFeatureUpdate}
              toggleDialogOpen={toggleDialogOpen}
              setCurrentTenant={setCurrentTenant}
              currentTenant={currentTenant!}
            />
          )}
        />
      </div>
    );
  } else {
    content = (
      <Typography data-testid="fnds-feature-flag-tenants-list-no-data">
        {t('featureFlagDefinitionTenants.noData')}
      </Typography>
    );
  }

  return (
    <div data-testid="feature-flag-tenants-list-page">
      <div>
        <Typography className={classes.heading} component="h2" variant="h2">
          {t('featureFlagDefinitionTenants.pageTitle')}
        </Typography>
        {content}
        {currentTenant && (
          <FeatureFlagTenantEditDialog
            isDialogOpen={isDialogOpen}
            dialogToggle={toggleDialogOpen}
            tenant={currentTenant!}
            flagDefinition={flagDefinition!}
            flagValue={flagValue}
            featureFlagName={findLabelByLocale(
              flagDefinition?.labels as IFeatureFlagLocalizedLabel[]
            )}
            onSave={dialogResponse}
          />
        )}
      </div>
    </div>
  );
};
