import * as React from 'react';
import { useParams } from 'react-router';
import { PageTemplate } from 'components/PageTemplate';
import { Subroutes, SubroutesProps } from 'routes/Subroutes';
import { useTranslation } from 'react-i18next';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { FeatureFlagDefinitionData, useFeatureFlagDefinitions } from '@bb-config-ui/feature-flags';
import { useAuthContext } from 'contexts/AuthContext';

export interface FeatureFlag extends SubroutesProps {}

export const FeatureFlagDefinition: React.FunctionComponent<FeatureFlag> = ({ routes }) => {
  const { t } = useTranslation();
  const { flagKey } = useParams<{ flagKey: string }>();
  const { idToken } = useAuthContext();
  const parseIdToken: string = idToken ?? '';
  const { error, loading, flagDefinitions } = useFeatureFlagDefinitions({
    accessToken: parseIdToken,
  });

  const flag: FeatureFlagDefinitionData | undefined = flagDefinitions?.find(
    (el: FeatureFlagDefinitionData) => el.flagKey === flagKey
  );

  let content = (
    <LoadingIndicator theme="dark" variant="fill-window" data-testid="loading-flag-definition" />
  );

  if (!loading && flagDefinitions) {
    content = (
      <PageTemplate
        breadcrumbProps={
          flag && {
            replaceText: { 'featureFlagDefinitionInfo.globalSettingsTitle': flag.flagKey! },
          }
        }
        showTabs
        title={
          flag
            ? flag.labels.find((element: any) => element.locale === 'en_US')?.label!
            : 'Not Found'
        }
        errorMessage={
          error && {
            title: t('featureFlagDefinitionInfo.error'),
            message: error.message,
          }
        }
      >
        <Subroutes routes={routes} />
      </PageTemplate>
    );
  }

  return <>{content}</>;
};
