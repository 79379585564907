// This displays a loading indicator instead of content while contexts are
// loading data.

import React from 'react';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useAuthorization } from 'hooks/useAuthorization';
import { useAuthContext } from 'contexts/AuthContext';

export const WaitForContexts: React.FunctionComponent = ({ children }) => {
  const { loading: authLoading } = useAuthContext();
  const { loading: authzLoading } = useAuthorization();

  if (authLoading || authzLoading) {
    return <LoadingIndicator theme="dark" variant="fill-window" data-testid="loading-contexts" />;
  }

  return <>{children}</>;
};
