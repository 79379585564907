import useRestApi from 'hooks/useRestApi';
import React from 'react';
import {
  License,
  TenantLicenseMapping,
  TenantLicenseMappingRequestType,
  UseTenantLicenseMappingCacheResult,
} from './useTenantLicenseMappings.types';
import { apiUrl } from '../../../utils/apiUrl';

export function useTenantLicenseMappings(
  tenantId: string,
  onMappingCommitted?: (
    licenseName: string,
    requestType: TenantLicenseMappingRequestType,
    success: boolean
  ) => void
): UseTenantLicenseMappingCacheResult {
  // Tenant-license mappings datasource
  const {
    data,
    loading,
    error,
    succeededRequests,
    failedRequests,
    loadingRequests,
    clearSucceededRequests,
    clearFailedRequests,
    doPatch,
    doPost,
  } = useRestApi(apiUrl('tenancy', `tenants/${tenantId}/licenses`));

  // Cache for modified tenant-license mappings that are currently being POSTed or PATCHed
  const [pendingMappings, setPendingMappings] = React.useState(
    new Map<string, TenantLicenseMapping>()
  ); //

  // Tenant-licenses results converted to a Map by licenseName for convenience
  const mappings = React.useMemo<Map<string, TenantLicenseMapping>>(() => {
    const fetchedMappings: TenantLicenseMapping[] = data?.results ?? [];
    return fetchedMappings.reduce(
      (resultsMap, mapping) => resultsMap.set(mapping.licenseName, mapping),
      new Map()
    );
  }, [data]);

  // Let go of pending mappings when we've received a new set ot results
  React.useEffect(() => setPendingMappings(new Map()), [data]);

  // Keeps track of a tenant-license mapping for which there is a modification request in process
  const onMappingProcessStart = (mapping: TenantLicenseMapping) =>
    setPendingMappings((prevMappings) =>
      prevMappings.set(mapping.licenseName, { ...mapping, isPending: true })
    );

  // Discard all failed mapping modifications
  failedRequests
    .map((request) => pendingMappings.get(request.annotation.licenseName))
    .forEach(
      (mapping) =>
        mapping &&
        setPendingMappings((prevMappings) => {
          prevMappings.delete(mapping.licenseName);
          return prevMappings;
        })
    );

  const requestTypeForModification = (
    modification: Partial<TenantLicenseMapping>
  ): TenantLicenseMappingRequestType | undefined => {
    if (modification.active !== undefined) {
      return modification.active ? 'activate' : 'deactivate';
    }
    if (modification.metadata !== undefined) {
      return 'saveMetadata';
    }
    return undefined;
  };

  // Modify a tenant license mapping with an API call
  const modify = (
    license: License,
    mappingModification: Partial<TenantLicenseMapping>
  ): boolean => {
    if (loading || loadingRequests || error || pendingMappings.has(license.licenseName)) {
      return false;
    }
    const mapping = mappings.get(license.licenseName);
    let modifiedMapping: TenantLicenseMapping;
    if (!mapping) {
      doPost(
        {
          ...mappingModification,
          licenseName: license.licenseName,
          metadata: license.defaultMetadata,
        },
        { licenseName: license.licenseName, requestType: 'add' as TenantLicenseMappingRequestType }
      );
      modifiedMapping = {
        active: true,
        metadata: license.defaultMetadata ?? {},
        ...mappingModification,
        licenseName: license.licenseName,
      };
    } else {
      doPatch(license.licenseName, mappingModification, {
        licenseName: license.licenseName,
        requestType: requestTypeForModification(mappingModification),
      });
      modifiedMapping = { ...mapping, ...mappingModification };
    }
    onMappingProcessStart(modifiedMapping);
    return true;
  };

  // Notify cache user about modification request results
  React.useEffect(() => {
    if (onMappingCommitted) {
      succeededRequests.forEach((request) =>
        onMappingCommitted(request.annotation.licenseName, request.annotation.requestType, true)
      );
      failedRequests.forEach((request) =>
        onMappingCommitted(request.annotation.licenseName, request.annotation.requestType, false)
      );
    }
    if (succeededRequests.length > 0) {
      clearSucceededRequests();
    }
    if (failedRequests.length > 0) {
      clearFailedRequests();
    }
  }, [
    clearFailedRequests,
    clearSucceededRequests,
    failedRequests,
    onMappingCommitted,
    succeededRequests,
  ]);

  return {
    error,
    loading: loading || loadingRequests,
    modify,
    getMapping: (licenseName) => pendingMappings.get(licenseName) ?? mappings.get(licenseName),
  };
}

export default useTenantLicenseMappings;
