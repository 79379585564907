import * as React from 'react';
import { useParams } from 'react-router';
import useRestApi from 'hooks/useRestApi';
import { useTranslation } from 'react-i18next';
import { useTenantContext } from 'contexts/TenantContext';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorMessage } from 'components/ErrorMessage/ErrorMessage';
import { PaginatedTable } from 'components/PaginatedTable';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Information } from '@bb-ui/icons/dist/small/Information';
import { createStyles, makeStyles, Theme } from '@bb-ui/react-library/dist/components/styles';
import { SortDirection } from '@bb-ui/react-library/dist/components/SortableTable/SortableTable.types';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { SortableTableHeaderCell } from '@bb-ui/react-library/dist/components/SortableTableHeaderCell';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { Tooltip } from '@bb-ui/react-library/dist/components/Tooltip';
import { useSnackbar } from 'hooks/useSnackbar';
import TenantLicenseActiveControl from './TenantLicenseActiveControl';
import TenantLicenseMetadataControl from './TenantLicenseMetadataControl';
import TenantLicenseMetadataDialog from './TenantLicenseMetadataDialog';
import useTenantLicenseMappings from './useTenantLicenseMappings';
import {
  License,
  TenantLicenseMapping,
  TenantLicenseMappingRequestType,
} from './useTenantLicenseMappings.types';
import { apiUrl } from '../../../utils/apiUrl';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleLabel: {
      marginBottom: theme.spacing(1),
    },
    infoLabel: {
      marginBottom: theme.spacing(2),
    },
    licenseNameCell: {
      maxWidth: 0,
      wordWrap: 'break-word',
    },
    activeSwitchCell: {
      width: 128,
      whiteSpace: 'nowrap',
    },
    metadataCell: {
      width: 200,
      whiteSpace: 'nowrap',
    },
    metadataInfoIcon: {
      position: 'relative',
      top: 3,
      left: 3,
    },
    unavailableLabel: {
      color: theme.palette.link.disabled,
    },
  }),
);

export const TenantLicenses: React.FunctionComponent = (props) => {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const { tenantId }: { tenantId: string } = useParams();
  const { tenant } = useTenantContext();
  const { enqueueSnackbar } = useSnackbar();

  // Licence list datasource
  const {
    data: licensesData,
    error: licensesError,
    loading: licensesLoading,
  } = useRestApi(apiUrl('tenancy', 'licenses'));
  const licenses = React.useMemo<License[]>(() => licensesData?.results ?? [], [licensesData]);

  // mappings datasource
  const handleMappingCommitted = (
    licenseName: string,
    requestType: TenantLicenseMappingRequestType,
    success: boolean,
  ) =>
    enqueueSnackbar(
      t(`tenantLicenses.notifications.${requestType}.${success ? 'success' : 'error'}`, {
        licenseName,
        tenantName: tenant?.name,
      }),
      { variant: success ? 'info' : 'error' },
    );
  const mappingCache = useTenantLicenseMappings(tenantId, handleMappingCommitted);

  // After mappings are fetched initially, don't go back to loading state when re-fetching
  const [initialMappingsLoading, setInitialMappingsLoading] = React.useState(true);
  React.useEffect(() => {
    if (initialMappingsLoading && !mappingCache.loading) {
      setInitialMappingsLoading(false);
    }
  }, [mappingCache, initialMappingsLoading]);

  const [licenceEditingMetadata, setLicenseEditingMetadata] = React.useState<string | null>(null);

  // modify the mapping `active` flag
  const handleMappingActiveChange = React.useCallback(
    (license: License, active: boolean) => {
      mappingCache.modify(license, { active });
    },
    [mappingCache],
  );

  // Filter licenses with the searchbox value
  const search =
    (filter: string) =>
    ({ licenseName }: License) =>
      licenseName.toLowerCase().includes(filter.toLowerCase());

  // keep track of how many licenses have an active mapping
  const activeCount = React.useMemo(
    () =>
      licenses.reduce(
        (count, { licenseName }) => count + (mappingCache.getMapping(licenseName)?.active ? 1 : 0),
        0,
      ),
    [mappingCache, licenses],
  );

  // SORTING
  // Don't re-sort on mapping changes - we don't want items to move "under fingers"
  // when mapping changes are done and the table is sorted in the "active" column
  // instead, save the sorted licenses into state.
  const [sortedLicenses, setSortedLicenses] = React.useState<License[]>([]);
  React.useEffect(() => setSortedLicenses(licenses), [licenses]);
  const handleSortChange = React.useCallback(
    (sortDirection?: SortDirection, sortColumnId?: string) => {
      setSortedLicenses(() => {
        const orderSign = sortDirection === 'asc' ? 1 : -1;

        // license name alphabetical sort
        if (sortColumnId === 'license') {
          return [...licenses].sort(
            (l1, l2) => orderSign * l1.licenseName.localeCompare(l2.licenseName),
          );
        }

        // sort mappings according to the active state
        if (sortColumnId === 'active') {
          return [...licenses].sort((l1, l2) => {
            // sort primarily by existing mapping, then by the active flag.
            // active: 0, inactive: 1, active & no mapping (impossible): 2, no mapping: 3
            const ord = (m?: TenantLicenseMapping) => (m ? 0 : 2) + (m?.active ? 0 : 1);
            // calculate ordinal for each item and return difference
            return (
              orderSign *
              (ord(mappingCache.getMapping(l1.licenseName)) -
                ord(mappingCache.getMapping(l2.licenseName)))
            );
          });
        }

        // sort by creation date
        if (sortColumnId === 'created') {
          return [...licenses].sort(
            (l1, l2) =>
              orderSign *
              ((mappingCache.getMapping(l1.licenseName)?.createdAt ?? 0) -
                (mappingCache.getMapping(l2.licenseName)?.createdAt ?? 0)),
          );
        }

        // sort by update date
        if (sortColumnId === 'updated') {
          return [...licenses].sort(
            (l1, l2) =>
              orderSign *
              ((mappingCache.getMapping(l1.licenseName)?.updatedAt ?? 0) -
                (mappingCache.getMapping(l2.licenseName)?.updatedAt ?? 0)),
          );
        }

        return licenses;
      });
    },
    [licenses, mappingCache],
  );

  function renderUpdatedAt(licenseName: string) {
    const updatedAt = mappingCache.getMapping(licenseName)?.updatedAt;
    const updatedAtFormatted = updatedAt
      ? new Date((updatedAt ?? 0) * 1000).toLocaleString()
      : null;

    return (
      updatedAtFormatted || (
        <Typography className={classes.unavailableLabel}>
          {t('tenantLicenses.dates.noData')}
        </Typography>
      )
    );
  }

  function renderCreatedAt(licenseName: string) {
    const createdAt = mappingCache.getMapping(licenseName)?.createdAt;
    const createdAtFormatted = createdAt
      ? new Date((createdAt ?? 0) * 1000).toLocaleString()
      : null;

    return (
      createdAtFormatted || (
        <Typography className={classes.unavailableLabel}>
          {t('tenantLicenses.dates.noData')}
        </Typography>
      )
    );
  }

  const getAriaSortMessage = (columnId?: string, sortDirection?: SortDirection) => {
    const columnLabel = t(`tenantLicenses.${columnId}`);
    const orderLabel =
      sortDirection === 'asc'
        ? t('global.paginatedTable.ascending')
        : t('global.paginatedTable.descending');
    return t('global.paginatedTable.sortedAriaMessage', {
      columnLabel,
      orderLabel,
    });
  };
  let content: React.ReactElement;

  if (licensesLoading || initialMappingsLoading) {
    content = <LoadingIndicator data-testid="tenant-licenses-init" />;
  } else if (licensesError) {
    content = (
      <ErrorMessage
        title={t('tenantLicenses.loadError')}
        message={licensesError.message}
        data-testid="tenant-licenses-error"
      />
    );
  } else if (!licensesData?.results || licensesData.results?.length === 0) {
    content = (
      <ErrorMessage
        title={t('tenantLicenses.loadError')}
        message={t('tenantLicenses.noData')}
        data-testid="tenant-licenses-no-data"
      />
    );
  } else if (mappingCache.error) {
    content = (
      <ErrorMessage
        title={t('tenantLicenses.loadError')}
        message={mappingCache.error.message}
        data-testid="tenant-licenses-mappings-error"
      />
    );
  } else {
    content = (
      <form data-testid="tenant-licenses-data">
        <Typography className={classes.infoLabel} variant="body1">
          {t('tenantLicenses.activeCountInfo', {
            count: licenses.length,
            activeCount,
          })}
        </Typography>
        <PaginatedTable
          onSortChanged={({ sortColumnId, sortDirection }) => {
            handleSortChange(sortDirection, sortColumnId);
            return true;
          }}
          getSortChangedAriaMessage={getAriaSortMessage}
          searchBoxProps={{ label: t('tenantLicenses.searchLabel') }}
          sortedData={sortedLicenses}
          search={search}
          noMatchesMessage={(searchExpression) => t('tenantLicenses.noMatch', { searchExpression })}
          renderHead={() => (
            <TableHead>
              <TableRow>
                <SortableTableHeaderCell
                  tableCellProps={{ className: classes.licenseNameCell }}
                  id="tenant-licenses-table-header-license_name"
                  columnId="license"
                >
                  {t('tenantLicenses.license')}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell
                  tableCellProps={{ className: classes.licenseNameCell }}
                  id="tenant-licenses-table-header-created_at"
                  columnId="created"
                >
                  {t('tenantLicenses.dates.createdAt')}
                </SortableTableHeaderCell>
                <SortableTableHeaderCell
                  tableCellProps={{ className: classes.licenseNameCell }}
                  id="tenant-licenses-table-header-updated_at"
                  columnId="updated"
                >
                  {t('tenantLicenses.dates.updatedAt')}
                </SortableTableHeaderCell>
                <TableCell
                  className={classes.metadataCell}
                  id="tenant-licenses-table-header-metadata"
                  tabIndex={-1}
                  role="columnheader"
                >
                  {t('tenantLicenses.metadata')}
                  <Tooltip
                    title={t<string>('tenantLicenses.metadataConnectedWhenLicenseMapped')}
                    placement="top-start"
                  >
                    <Information className={classes.metadataInfoIcon} />
                  </Tooltip>
                </TableCell>
                <SortableTableHeaderCell
                  tableCellProps={{ className: classes.activeSwitchCell }}
                  id="tenant-licenses-table-header-active"
                  columnId="active"
                >
                  {t('tenantLicenses.active')}
                </SortableTableHeaderCell>
              </TableRow>
            </TableHead>
          )}
          renderRow={({ licenseName, defaultMetadata }, index) => (
            <TableRow
              key={licenseName}
              aria-rowindex={index + 1}
              data-testid={`tenant-licenses-table-row-${licenseName}`}
            >
              <TableCell
                className={classes.licenseNameCell}
                aria-colindex={1}
                tabIndex={-1}
                aria-describedby="tenant-licenses-table-header-license_name"
              >
                {licenseName}
              </TableCell>
              <TableCell
                className={classes.licenseNameCell}
                aria-colindex={2}
                tabIndex={-1}
                aria-describedby="tenant-licenses-table-header-created_at"
              >
                {renderCreatedAt(licenseName)}
              </TableCell>
              <TableCell
                className={classes.licenseNameCell}
                aria-colindex={3}
                tabIndex={-1}
                aria-describedby="tenant-licenses-table-header-updated_at"
              >
                {renderUpdatedAt(licenseName)}
              </TableCell>
              <TableCell
                className={classes.metadataCell}
                aria-colindex={4}
                aria-describedby="tenant-licenses-table-header-metadata"
              >
                <TenantLicenseMetadataControl
                  id={licenseName}
                  hasMetadata={!!mappingCache.getMapping(licenseName)}
                  disabled={mappingCache.loading}
                  onEditMetadata={() => setLicenseEditingMetadata(licenseName)}
                />
              </TableCell>
              <TableCell
                className={classes.activeSwitchCell}
                aria-colindex={5}
                aria-describedby="tenant-licenses-table-header-active"
              >
                <TenantLicenseActiveControl
                  id={licenseName}
                  mapping={mappingCache.getMapping(licenseName)}
                  disabled={mappingCache.loading}
                  onActiveChange={(active: boolean) =>
                    handleMappingActiveChange({ licenseName, defaultMetadata }, active)
                  }
                />
              </TableCell>
            </TableRow>
          )}
        />
      </form>
    );
  }

  return (
    <div data-testid="tenant-licenses-page">
      <Typography className={classes.titleLabel} variant="h2">
        {t('tenantLicenses.contentTitle')}
      </Typography>
      {content}
      {licenceEditingMetadata && (
        <TenantLicenseMetadataDialog
          id="tenant-licenses-metadata-dialog"
          open
          licenseName={licenceEditingMetadata}
          onClose={() => setLicenseEditingMetadata(null)}
          onSave={(metadata) =>
            mappingCache.modify({ licenseName: licenceEditingMetadata }, { metadata })
          }
          initialData={mappingCache.getMapping(licenceEditingMetadata)?.metadata ?? {}}
        />
      )}
    </div>
  );
};
