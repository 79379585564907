// This manages access to runtime configuration as set in a separate file
// deployed with this application.

import React from 'react';
import useAxios from 'axios-hooks';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'components/ErrorMessage';

// Config properties.

interface ConfigData {
  cognito?: {
    returnUrl: string;
    signInUrl: string;
    tenantId: string;
    isIl4?: boolean;
    signOutReturnUrl?: string;
  };
  mainAuth0?: {
    // Values set in the internal Auth0 admin for this application.
    clientId: string;
    domain: string;
    redirectUri: string;

    // Prefix for all Blackboard-related claims in the token.
    bbNamespace: string;
  };
  supportAuth0?: {
    // Auth0 support persona application regional configurations
    regions: Record<string, { clientId: string; domain: string }>;

    // Callback URI for the support persona login process to redirect to after completing
    redirectUri: string;

    // Prefix for all Blackboard-related claims in the token.
    bbNamespace: string;
  };
  // URL to redirect the support persona after a successful login
  supportRedirectUrl?: string;
  // bbSignIn will not be present in internal deployments.
  bbSignIn?: {
    returnUrl: string;
    url: string;
  };
  deployment?: {
    internal: boolean;
  };
  featureFlagSupportedRegions?: string[];
  tenantSupportedRegions?: string[];
}

export interface AppConfigProps extends ConfigData {
  loading: boolean;
}

export interface AppConfigProviderProps {
  pathname: string;
}

export const AppConfigContext = React.createContext<AppConfigProps>({ loading: true });

export const useAppConfigContext = () => React.useContext(AppConfigContext);

export const AppConfigProvider: React.FunctionComponent<AppConfigProviderProps> = ({
  children,
}) => {
  const fileName =
    process.env.NODE_ENV === 'development'
      ? `config.${process.env.REACT_APP_DEV_DEPLOYMENT}.json`
      : 'config.json';
  const [{ data, loading, error }] = useAxios(`${process.env.PUBLIC_URL}/${fileName}`);
  const storedTenant = window.localStorage.getItem('auth-tenant-uri-fragment');
  const { t } = useTranslation();

  if (error) {
    return (
      <ErrorMessage title={t('global.configError')} message={error.message} variant="fill-window" />
    );
  }

  let context: AppConfigProps = { loading };

  if (data) {
    context = { ...data, ...context };
  }

  if (context.cognito && storedTenant) {
    context = { ...context, cognito: { ...context.cognito, tenantId: storedTenant } };
  }

  return <AppConfigContext.Provider value={context}>{children}</AppConfigContext.Provider>;
};

export default withRouter(({ location, children }) => (
  <AppConfigProvider pathname={location.pathname}>{children}</AppConfigProvider>
));
