import * as React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import classnames from 'classnames';
import { matchPath, Link, useLocation } from 'react-router-dom';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { useTranslation } from 'react-i18next';
// TODO: add to bb-ui
import { default as MuiBreadcrumbs, BreadcrumbsProps as MuiBreadcrumbsProps } from '@material-ui/core/Breadcrumbs';
import { AppRouteProps } from 'routes/AppRoute';
import { useAppRouteContext } from 'contexts/AppRouteContext';

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  replaceText?: { [key: string]: string };
}

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    fontSize: theme.typography.fontSizeSmall,
  },
}));

export const Breadcrumbs: React.FunctionComponent<BreadcrumbsProps> = (props) => {
  const { replaceText, className, ...other } = props;
  const { allRoutes } = useAppRouteContext();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const classes = useStyles(props);

  // Recursively search for the deepest route config matching the current
  // pathname. If there is more than one matching route config at a specific
  // level, we choose the longest path. We need to consider every route config
  // because we will match on a parent route before its children.
  //
  // This is frustratingly similar to what we do in PageTabs but not sure how to
  // generalize the logic.

  function routeForPath(path: string, routes: AppRouteProps[]) {
    let result: AppRouteProps | undefined;
    let resultSpecificity = -1;

    function search(routes: AppRouteProps[], currentSpecificity = 0): void {
      routes.forEach(route => {
        if (matchPath(path, { exact: route.exact, path: route.path }) &&
          (!result ||
            (currentSpecificity > resultSpecificity) ||
            (currentSpecificity === resultSpecificity && result.path.length < route.path.length))
        ) {
          result = route;
          resultSpecificity = currentSpecificity;
        }

        // Check nested routes.

        if (route.routes) {
          search(route.routes, currentSpecificity + 1);
        }
      });
    }

    search(routes);
    return result;
  }

  function popPath(path: string) {
    return path.replace(/\/[^/]*$/, '');
  }

  let path = pathname;
  let breadcrumbs: ({ path: string; name: string })[] = [];

  while (path.indexOf('/') !== -1) {
    const route = routeForPath(path, allRoutes);

    if (route && route.name) {
      breadcrumbs.unshift({ path, name: (replaceText && replaceText[route.name]) || t(route.name) });
    }

    path = popPath(path);
  }

  // Special case: if there is only one breadcrumb, show nothing.

  if (breadcrumbs.length < 2) {
    breadcrumbs = [];
  }

  return (
    <MuiBreadcrumbs className={classnames(classes.root, className)} data-testid="breadcrumbs" {...other}>
      {breadcrumbs.map(({ name, path }, index) => (
        (index === breadcrumbs.length - 1) ?
          <Typography variant="inherit" key={path}>{name}</Typography> :
          <Link color="inherit" to={path} key={path}>{name}</Link>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
